import { Theme } from '../../themes'

interface InfoProps {
  color?: string
}

const Info = ({ color }: InfoProps) => {
  return (
    <svg height="24" width="24" viewBox="0 0 24 24">
      <path
        fill={color ?? Theme().Colors.Primary.Yellow}
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"
      ></path>
    </svg>
  )
}

export default Info
