import React, { useCallback } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useShopifyContext } from '../shared/contexts/Shopify'

const PrivateRoute = ({ component, ...rest }: any) => {
  const { shopParam } = useShopifyContext()

  const routeComponent = useCallback(
    (props: any) => {
      return shopParam ? React.createElement(component, props) : <Redirect to={{ pathname: '/login' }} />
    },
    [component, shopParam]
  )

  return <Route {...rest} render={routeComponent} />
}

export default PrivateRoute
