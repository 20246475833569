import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserRecords } from '../../interface/UserDetailsInterface'
import ShopifyServices from '../../services/ShopifyServices'

//thunk function
export const fetchUserDetails = createAsyncThunk('userProfile/fetchUserDetails', async () => {
  const data = await ShopifyServices.getUserDetails()
  const [record] = data?.records || []
  return record || {}
})

interface UserDetailProps {
  isLoading: boolean
  isError: boolean
  record: Partial<IUserRecords>
}

const initialState: UserDetailProps = {
  isLoading: true,
  isError: false,
  record: {}
}

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setRecord(state, action: PayloadAction<IUserRecords>) {
      state.record = { ...state.record, ...action.payload }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserDetails.pending, state => {
      state.isLoading = true
    }),
      builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.record = action.payload
      }),
      builder.addCase(fetchUserDetails.rejected, state => {
        state.isLoading = false
        state.isError = true
      })
  }
})

export const { setLoading, setRecord } = userProfileSlice.actions

export default userProfileSlice
