import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import { IScanRecord } from '../../interface/Scans'
import { ISpeedStatsChart } from '../../interface/ScanListByAreaInterface'
import ShopifyServices from '../../services/ShopifyServices'
import { calculateAverage } from '../../utils/Common'

interface IParams {
  area?: string
}

const formatScanData = (item: IScanRecord) => {
  let desktopScore = 0

  if (item.gtmetrix_score && item.google_desktop_score) {
    desktopScore = calculateAverage([item.gtmetrix_score, item.google_desktop_score])
  } else if (item.gtmetrix_score) {
    desktopScore = item.gtmetrix_score
  } else if (item.google_desktop_score) {
    desktopScore = item.google_desktop_score
  }
  return {
    id: item.id,
    Date: item.created_at,
    Average: calculateAverage([desktopScore, item.google_mobile_score]),
    Desktop: desktopScore,
    Mobile: item.google_mobile_score
  }
}

export const fetchScanListByArea = createAsyncThunk<ISpeedStatsChart[], IParams>(
  'scanList/fetchScanListByArea',
  async param => {
    const { area } = param
    const scanListByArea = await ShopifyServices.getScanListByArea(area)
    const records = scanListByArea?.records || []

    const data = records.map((item: IScanRecord) => formatScanData(item))
    return data
  }
)

interface ScanListProps {
  isLoading: boolean
  isError: boolean
  data: ISpeedStatsChart[]
}

const initialState: ScanListProps = {
  isLoading: true,
  isError: false,
  data: []
}

const scanListByAreaSlice = createSlice({
  name: 'Scan List by Area',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setScanListByAreaRecord(state, action: PayloadAction<ISpeedStatsChart[]>) {
      state.data = action.payload
    },
    setScanListByAreaChanges(state, { payload }: PayloadAction<IScanRecord>) {
      const newAreaList = cloneDeep(state.data)
      newAreaList.unshift(formatScanData(payload))
      newAreaList.pop()
      state.data = newAreaList
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchScanListByArea.pending, state => {
      state.isLoading = true
    })
    builder.addCase(fetchScanListByArea.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.data = payload
    })
    builder.addCase(fetchScanListByArea.rejected, state => {
      state.isLoading = false
      state.isError = true
    })
  }
})

export const { setLoading, setScanListByAreaRecord, setScanListByAreaChanges } = scanListByAreaSlice.actions

export default scanListByAreaSlice
