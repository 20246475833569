import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import App from './App'
import SocketsProvider from './shared/contexts/Sockets'
import { store } from './shared/redux/store'

dayjs.extend(utc)
dayjs.extend(timezone)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketsProvider>
        <App />
      </SocketsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
