import { Theme } from '../themes'

export const DashboardItems = [
  { label: 'Average', hexColor: Theme().Colors.Primary.Green },
  { label: 'Mobile', hexColor: Theme().Colors.Primary.Red },
  { label: 'Desktop', hexColor: Theme().Colors.Primary.Blue }
]

export const Devices = {
  Desktop: 'desktop',
  Mobile: 'mobile'
}

export const ScanAreas = {
  All: 'All',
  Home: 'Homepage',
  Collections: 'Collections'
}

export const Sorting = {
  Ascending: 'ASC',
  Descending: 'DESC'
}

export const ScanHistoryDeviceFilters = [
  { label: 'Desktop', value: Devices.Desktop },
  { label: 'Mobile', value: Devices.Mobile }
]

export const ScanHistoryFilters = [
  { label: 'Most Recent', value: Sorting.Descending },
  { label: 'Oldest', value: Sorting.Ascending }
]

export const SpeedStatsFilters = [
  { label: ScanAreas.All, value: ScanAreas.All },
  { label: ScanAreas.Home, value: ScanAreas.Home },
  { label: ScanAreas.Collections, value: ScanAreas.Collections }
]
