import { Theme } from '../themes'

export enum TagColorKeys {
  Blue = 'Blue',
  Green = 'Green',
  Red = 'Red',
  Yellow = 'Yellow'
}

export const TagColors: {
  [key in TagColorKeys]: {
    labelColor: string
    backgroundColor: string
    borderColor: string
  }
} = {
  Blue: {
    labelColor: Theme().Colors.Primary.Blue,
    backgroundColor: '#E6E6FF',
    borderColor: '#529Cf7'
  },
  Green: {
    labelColor: Theme().Colors.Primary.Green,
    backgroundColor: '#ECFEEC',
    borderColor: '#A9DEAB'
  },
  Red: {
    labelColor: Theme().Colors.Primary.Red,
    backgroundColor: '#FFEDEF',
    borderColor: '#E3B3B8'
  },
  Yellow: {
    labelColor: Theme().Colors.Primary.Brown,
    backgroundColor: '#FEF3CC',
    borderColor: '#E0CF94'
  }
}
