import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { io, Socket } from 'socket.io-client'
import { API_URL, SOCKET_SECRET } from '../configs/config'
import { useAppSelector } from '../hooks/redux'

interface IProps {
  children: JSX.Element | JSX.Element[]
}

interface IContextValue {
  socket: Socket
  isConnected: boolean
}

const socket = io(API_URL, {
  transports: ['websocket', 'polling', 'flashsocket'],
  auth: { secretKey: SOCKET_SECRET },
  path: '/api/socket.io'
})

const SocketsContext = createContext({
  socket,
  isConnected: false
} as unknown as IContextValue)

const SocketsProvider = ({ children }: IProps) => {
  const userId = useAppSelector(state => state.userProfileSlice.record.id)
  const [isConnected, setIsConnected] = useState(false)

  useEffectOnce(() => {
    socket.on('connect', () => setIsConnected(socket.connected))
    socket.on('disconnect', () => setIsConnected(socket.disconnected))
    socket.on('connect_error', err => console.log(err.message))
    socket.on('message', data => console.log(data))

    return () => {
      socket.removeAllListeners()
    }
  })

  useEffect(() => {
    if (userId) socket.emit('subscribe', { userId })
  }, [userId])

  const states = useMemo(() => ({ isConnected, socket }), [isConnected])

  return <SocketsContext.Provider value={states}>{children}</SocketsContext.Provider>
}

export default SocketsProvider

export const useSocketsContext = () => {
  return useContext(SocketsContext)
}
