export const NODE_ENV = process.env.NODE_ENV
export const IS_PROD_BUILD = NODE_ENV === 'production'

export const API_URL = process.env.REACT_APP_API_URL || ''

/* SHOPIFY */
export const SHOPIFY_URL = process.env.REACT_APP_SHOPIFY_URL || ''
export const SHOPIFY_API_KEY = process.env.REACT_APP_SHOPIFY_API_KEY || ''
export const SHOPIFY_API_SECRET = process.env.REACT_APP_SHOPIFY_API_SECRET || ''
export const SHOPIFY_HOST = process.env.REACT_APP_SHOPIFY_HOST || ''

export const SOCKET_SECRET = process.env.REACT_APP_SOCKET_SECRET || ''
