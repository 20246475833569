import Check from '../assets/js/Check'
import Info from '../assets/js/Info'
import Warning from '../assets/js/Warning'
import { SpeedRange } from '../constants/Status'
import { TagColorKeys } from '../constants/Tag'
import { UrlResultData } from '../interface/UrlResultInterface'
import { Theme } from '../themes'

export const formatUrls = (baseURL: string, endpoints: UrlResultData[]) => {
  return endpoints.map(endpoint => {
    const scanObject = { newUrl: '', area: '' }
    scanObject.newUrl = endpoint.id > 2 ? `${endpoint.url}` : 'https://' + baseURL + endpoint.url
    scanObject.area = endpoint.title
    return scanObject
  })
}

export const calculateAverage = (data: number[]) => {
  const sum = data.reduce((a, b) => a + b, 0)
  const average = sum / data.length || 0
  return average
}

export const displayStatus = (score: number, color?: string) => {
  if (score >= SpeedRange.Slow.Min && score <= SpeedRange.Slow.Max) {
    return {
      icon: <Warning color={color} />,
      label: 'Slow',
      color: Theme().Colors.Primary.Red,
      colorKey: TagColorKeys.Red
    }
  } else if (score >= SpeedRange.Averange.Min && score <= SpeedRange.Averange.Max) {
    return {
      icon: <Info color={color} />,
      label: 'Average',
      color: Theme().Colors.Primary.Yellow,
      colorKey: TagColorKeys.Yellow
    }
  } else if (score >= SpeedRange.Fast.Min && score <= SpeedRange.Fast.Max) {
    return {
      icon: <Check color={color} />,
      label: 'Fast',
      color: Theme().Colors.Primary.Green,
      colorKey: TagColorKeys.Green
    }
  }
}
