import { useEffectOnce } from 'react-use'
import { useShopifyContext } from '../shared/contexts/Shopify'
import { useAppDispatch } from '../shared/hooks/redux'
import { setUrl } from '../shared/redux/reducers/pageListSlice'
import { fetchUserDetails } from '../shared/redux/reducers/userProfileReducer'
import ShopifyServices from '../shared/services/ShopifyServices'

const Initializer = () => {
  const dispatch = useAppDispatch()
  const { shopParam } = useShopifyContext()

  useEffectOnce(() => {
    if (!shopParam) return
    dispatch(fetchUserDetails())
    async function getUrlCollection() {
      const result = await ShopifyServices.getCollectionPages()
      dispatch(setUrl(result.results))
    }
    getUrlCollection()
  })

  return null
}
export default Initializer
