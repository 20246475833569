import { UrlResultData } from '../interface/UrlResultInterface'

export const ShopUrls = [
  {
    id: 1,
    title: 'Homepage',
    page: 'homepage',
    type: 'Homepage',
    url: '/'
  },
  {
    id: 2,
    title: 'Collections',
    page: 'collections',
    type: 'collections',
    url: '/collections'
  }
] as UrlResultData[]

export enum Area {
  Homepage = 'homepage',
  Collection = 'collections',
  Product = 'products'
}
