import { configureStore } from '@reduxjs/toolkit'
import arcComponentSlice from './reducers/arcComponentReducer'
import userProfileReducer from './reducers/userProfileReducer'
import scanListSlice from './reducers/scanListSlice'
import scanListByAreaSlice from './reducers/scanListByAreaSlice'
import pageListSlice from './reducers/pageListSlice'

export const store = configureStore({
  reducer: {
    userProfileSlice: userProfileReducer.reducer,
    arcComponentSlice: arcComponentSlice.reducer,
    scanList: scanListSlice.reducer,
    scanListByArea: scanListByAreaSlice.reducer,
    pageList: pageListSlice.reducer
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware()
  }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
