import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UrlResultData } from '../../interface/UrlResultInterface'

interface ArcComponentProps {
  isLoading: boolean
  isError: boolean
  urls: UrlResultData[]
}

const initialState: ArcComponentProps = {
  isLoading: true,
  isError: false,
  urls: []
}

const pageListSlice = createSlice({
  name: 'arcComponent',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setUrl(state, action: PayloadAction<UrlResultData[]>) {
      state.urls = action.payload
    }
  }
})

export const { setLoading, setUrl } = pageListSlice.actions

export default pageListSlice
