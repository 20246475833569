import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IScanRecord } from '../../interface/Scans'
import ShopifyServices from '../../services/ShopifyServices'

export const fetchArcComponentData = createAsyncThunk('arcComponent/fetchArcComponentData', async () => {
  const data = await ShopifyServices.getLastScanDetails()
  const [record] = data?.records || []
  return record || {}
})

interface ArcComponentProps {
  isLoading: boolean
  isError: boolean
  record: Partial<IScanRecord>
}

const initialState: ArcComponentProps = {
  isLoading: true,
  isError: false,
  record: {}
}

const arcComponentSlice = createSlice({
  name: 'arcComponent',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setRecord(state, action: PayloadAction<IScanRecord>) {
      state.record = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchArcComponentData.pending, state => {
      state.isLoading = true
    })
    builder.addCase(fetchArcComponentData.fulfilled, (state, action) => {
      state.isLoading = false
      state.record = action.payload
    })
    builder.addCase(fetchArcComponentData.rejected, state => {
      state.isLoading = false
      state.isError = true
    })
  }
})

export const { setLoading, setRecord } = arcComponentSlice.actions

export default arcComponentSlice
