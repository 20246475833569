import { Theme } from '../themes'

export const Plans: {
  [index: string]: { name: string; maxScanAttempts: null | number }
} = {
  Basic: {
    name: 'Basic',
    maxScanAttempts: 1
  },
  Business: {
    name: 'Business',
    maxScanAttempts: 3
  },
  Enterprise: {
    name: 'Enterprise',
    maxScanAttempts: null
  }
}

export enum Title {
  Enterprise = 'Enterprise',
  Business = 'Business',
  Free = 'Free',
  Basic = 'Basic'
}

export const PLANS = [
  {
    hTitle: Title.Basic,
    hSubtitle: 'Free Basic Monitoring',
    pTitle: 'Free',
    fList: [
      { included: true, description: '1 scan per day' },
      { included: true, description: 'Weekly email tips' },
      { included: true, description: 'Email support' },
      { included: false, description: 'Speed Report' },
      { included: false, description: 'Premium Support' }
    ],
    planColor: Theme().Colors.Primary.Green
  },

  {
    hTitle: Title.Business,
    hSubtitle: 'Proactive Monitoring',
    pTitle: '$1.99',
    fList: [
      { included: true, description: '3 automated page scans per day' },
      { included: true, description: 'Weekly email tips' },
      { included: true, description: 'Speed report' },
      { included: true, description: 'Email support' },
      { included: false, description: 'Premium Support - Optimizer assistance via email' }
    ],
    planColor: Theme().Colors.Primary.Red
  },
  {
    hTitle: Title.Enterprise,
    hSubtitle: 'White-glove store monitoring & Optimization service',
    pTitle: '$19.99',
    fList: [
      {
        included: true,
        description: (
          <>
            <b>Unlimited</b> automated daily scans
          </>
        )
      },
      { included: true, description: 'Weekly email tips' },
      { included: true, description: 'Speed report' },
      { included: true, description: 'Premium support' },
      { included: true, description: 'Dedicated Optimizer to speed up your store' }
    ],
    planColor: Theme().Colors.Primary.Blue
  }
]
