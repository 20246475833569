import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { merge, keyBy, values, orderBy, cloneDeep } from 'lodash'

import { Sorting } from '../../constants/Dashboard'
import { IScanRecord } from '../../interface/Scans'
import ShopifyServices from '../../services/ShopifyServices'

interface IParams {
  sorting: string
  offset?: number | undefined
}

interface IOutput {
  data: IScanRecord[]
  ordering: string
}

export const fetchScanList = createAsyncThunk<IOutput, IParams>('scanList/fetchScanList', async param => {
  const { sorting: ordering, offset } = param
  const scanListData = await ShopifyServices.getScanList(ordering, offset)
  const data = scanListData?.records || []

  return {
    data,
    ordering
  }
})

interface ScanListProps {
  isLoading: boolean
  isError: boolean
  data: IScanRecord[]
}

const initialState: ScanListProps = {
  isLoading: true,
  isError: false,
  data: []
}

const scanListSlice = createSlice({
  name: 'Scan List',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setScanListSliceRecord(state, action: PayloadAction<IScanRecord[]>) {
      state.data = action.payload
    },
    setScanListChanges(state, action: PayloadAction<IScanRecord[]>) {
      const scansChanges = cloneDeep(state.data)
      action.payload.forEach(scanItem => {
        const index = scansChanges.findIndex(history => history.id === scanItem.id)
        if (index === -1) scansChanges.unshift(scanItem)
        else scansChanges[index] = scanItem
      })
      state.data = scansChanges
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchScanList.pending, state => {
      state.isLoading = true
    })
    builder.addCase(fetchScanList.fulfilled, (state, { payload }) => {
      const merged = merge(keyBy(state.data, 'id'), keyBy(payload.data, 'id'))
      const records = orderBy(values(merged), ['created_at'], [payload.ordering === Sorting.Ascending ? 'asc' : 'desc'])

      state.isLoading = false
      state.data = records
    })
    builder.addCase(fetchScanList.rejected, state => {
      state.isLoading = false
      state.isError = true
    })
  }
})

export const { setLoading, setScanListSliceRecord, setScanListChanges } = scanListSlice.actions

export default scanListSlice
