import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

import { styled, Theme } from '../../shared/themes'
import { Breakpoint } from '../../shared/themes/Breakpoints'

interface ConfirmDialogProps {
  id: string
  isConfirmOpen: boolean
  title?: string | React.ReactNode
  message?: string | React.ReactNode
  cancelLabel?: string
  confirmLabel?: string
  onClose: () => void
  onConfirm: () => void
}

const ConfirmDialog = ({
  isConfirmOpen,
  id,
  title,
  message = 'Proceed?',
  cancelLabel = 'Not Now',
  confirmLabel = 'Yes',
  onClose,
  onConfirm
}: ConfirmDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isConfirmOpen)
      return () => {
        setIsLoading(false)
      }
  }, [isConfirmOpen])

  const handleCancel = () => {
    setIsLoading(false)
    onClose()
  }

  const handleOk = () => {
    setIsLoading(true)
    onConfirm()
  }

  return (
    <DialogWrapper
      id={id}
      classes={{
        paper: 'mui-confirm-container'
      }}
      maxWidth="xs"
      open={isConfirmOpen}
      onBackdropClick={handleCancel}
      onClose={handleCancel}
    >
      {title && <DialogTitle classes={{ root: 'mui-confirm-title' }}>{title}</DialogTitle>}
      <DialogContent classes={{ root: 'mui-confirm-content' }}>{message}</DialogContent>
      <DialogActions classes={{ root: 'mui-confirm-actions-container' }}>
        <Button classes={{ root: 'mui-button mui-button--secondary' }} onClick={handleCancel}>
          {cancelLabel}
        </Button>
        <Button
          classes={{ root: 'mui-button mui-button--primary' }}
          variant="contained"
          disabled={isLoading}
          onClick={handleOk}
        >
          {isLoading ? <CircularProgress color="inherit" classes={{ root: 'loading-icon' }} /> : confirmLabel}
        </Button>
      </DialogActions>
    </DialogWrapper>
  )
}

export default ConfirmDialog

const DialogWrapper = styled(Dialog)`
  font-family: 'Poppins', sans-serif;

  .mui-confirm-container {
    padding: 25px;
    width: 100%;
  }

  .mui-confirm-title {
    padding: 0 0 20px;
    font-family: inherit;
    font-weight: 600;
    letter-spacing: 0.2px;

    span {
      color: ${Theme().Colors.Primary.Blue};
    }
  }

  .mui-confirm-content {
    padding: 0 0 30px;

    span {
      color: ${Theme().Colors.Primary.Blue};
      font-weight: 500;
    }
  }

  .mui-button {
    font-family: inherit;
    padding: 10px 30px;
    margin-bottom: 10px;

    &:not(:first-of-type) {
      margin-left: 15px;
    }

    &.mui-button--primary,
    &.mui-button--secondary {
      font-weight: 600;
    }

    &.mui-button--primary {
      box-shadow: none;

      .loading-icon {
        height: 1.5rem !important;
        width: 1.5rem !important;
        margin: 0 10px;

        svg {
          height: inherit;
        }
      }
    }

    &.mui-button--secondary {
      background-color: ${Theme().Colors.Grey.Lighter};
      color: ${Theme().Colors.Text.Dark.MidPrio};

      &:hover {
        background-color: ${Theme().Colors.Grey.Light};
      }
    }

    @media ${Breakpoint.Phone} {
      padding: 10px 20px;
    }
  }
`
