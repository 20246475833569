import React, { createContext, useContext, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import useQuery from '../hooks/useQuery'
import { IS_PROD_BUILD, SHOPIFY_API_KEY } from '../configs/config'

interface ShopifyProps {
  children: JSX.Element | JSX.Element[]
}

interface IContextValue {
  shopParam: string
}

const ShopifyContext = createContext({
  shopParam: ''
} as IContextValue)

const ShopifyProvider: React.FC<ShopifyProps> = ({ children }) => {
  const query = useQuery()
  const [shopParam] = useState(() => query.get('shop') || '')

  useEffectOnce(() => {
    const ancestorOrigin = window?.location?.ancestorOrigins?.[0] || null
    if (IS_PROD_BUILD && shopParam && ancestorOrigin !== `https://${shopParam}`) {
      window.location.href = `https://${shopParam}/admin/apps/${SHOPIFY_API_KEY}`
      return
    }
  })

  const states = useMemo(() => ({ shopParam }), [shopParam])

  return <ShopifyContext.Provider value={states}>{children}</ShopifyContext.Provider>
}

export default ShopifyProvider

export const useShopifyContext = () => {
  return useContext(ShopifyContext)
}
