import defaultStyled, { createGlobalStyle, ThemeProvider as SThemeProvider } from 'styled-components'

const Colors = {
  Primary: {
    MidnightBlue: '#32354E',
    LightBlue: '#D9EBFC',
    Blue: '#0672F8',
    Green: '#20BE69',
    Red: '#DD626E',
    Yellow: '#FFA400',
    Brown: '#634E00'
  },
  Grey: {
    Dark: '#B9BACA',
    Light: '#EDEEF2',
    Lighter: '#FAFAFB'
  },
  Text: {
    Dark: {
      HighPrio: '#363636',
      MidPrio: 'rgba(50, 53, 78, 0.6)',
      LowPrio: 'rgba(54, 54, 54, 0.3)'
    },
    Light: {
      HighPrio: '#FFFFFF',
      MidPrio: 'rgba(255, 255, 255, 0.6)',
      LowPrio: 'rgba(255, 255, 255, 0.3)'
    }
  }
}

export const Theme = () => {
  return {
    Colors
  }
}

export const ThemeProvider = SThemeProvider
export const styled = defaultStyled
export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    height: 100vh;
    width: 100vw;
    background-color: #f4f7fd;
    color: #32354e;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  a {
    text-decoration: none;
  }

  code {
    font-size: 0.8rem;
  }
`
