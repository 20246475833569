import { Fragment, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Routes } from './shared/constants/Routes'
import { GlobalStyle } from './shared/themes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Login from './pages/Login'
import Loading from './pages/Loading'
import NotFoundPage from './pages/NotFoundPage'
import ShopifyProvider from './shared/contexts/Shopify'
import Initializer from './components/Initializer'
import PrivateRoute from './components/PrivateRoute'

import Layout from './components/Layout'

const DashboardPage = lazy(() => import('./pages/Dashboard'))
const ScanPage = lazy(() => import('./pages/Scan'))
const ScanDetailPage = lazy(() => import('./pages/ScanDetail'))
const FeaturesPage = lazy(() => import('./pages/Features'))
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'))
const TermsOfServicePage = lazy(() => import('./pages/TermsOfService'))

export default function App() {
  return (
    <Fragment>
      <GlobalStyle />
      <Router>
        <ShopifyProvider>
          <Initializer />
          <ToastContainer position="top-right" autoClose={3000} />
          <Switch>
            <Route exact path={Routes.Login} component={Login} />
            <Layout>
              <Suspense fallback={<Loading />}>
                <PrivateRoute exact path={Routes.Dashboard} component={DashboardPage} />
                <PrivateRoute exact path={Routes.Scan} component={ScanPage} />
                <PrivateRoute exact path={Routes.Features} component={FeaturesPage} />
                <PrivateRoute path={`${Routes.Scan}/:scanId`} component={ScanDetailPage} />
                <Route exact path={Routes.Privacy} component={PrivacyPolicyPage} />
                <Route exact path={Routes.Terms} component={TermsOfServicePage} />
              </Suspense>
            </Layout>
            <Route component={NotFoundPage} />
          </Switch>
        </ShopifyProvider>
      </Router>
    </Fragment>
  )
}
