import { Button, InputBase, Paper } from '@mui/material'
import { useState } from 'react'

import ScoreUpLogo from '../shared/assets/png/ScoreUpIcon.png'
import { SHOPIFY_API_KEY, SHOPIFY_HOST } from '../shared/configs/config'
import ShopifyServices from '../shared/services/ShopifyServices'
import { styled, Theme } from '../shared/themes'
import { Breakpoint } from '../shared/themes/Breakpoints'

const ShopifyLogin = () => {
  const [shopifyDomain, setShopifyDomain] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleOnClick = () => {
    setIsLoading(true)
    ShopifyServices.login(shopifyDomain)
      .then(() => {
        window.location.href = `https://${shopifyDomain}/admin/apps/${SHOPIFY_API_KEY}`
      })
      .catch(err => {
        const message = err?.response?.data?.message
        if (message === 'Unregistered user') {
          window.location.href = `${SHOPIFY_HOST}/api/shopify/install?shop=${shopifyDomain}`
          return
        }
        setIsLoading(false)
      })
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setShopifyDomain(value)
  }

  return (
    <LoginWrapper>
      <LoginContainerWrapper>
        <LogoWrapper>
          <img src={ScoreUpLogo} />
          <h1 className="logo-name">
            <span>Score</span>Up
          </h1>
        </LogoWrapper>

        <LoginFormWrapper>
          <InputLabelWrapper>Enter you Shopify domain to login.</InputLabelWrapper>
          <InputWrapper
            value={shopifyDomain}
            id="domain-input"
            placeholder="example.myshopify.com"
            onChange={onInputChange}
          />
          <ButtonWrapper onClick={handleOnClick} disabled={isLoading || !shopifyDomain.trim()}>
            Install
          </ButtonWrapper>
        </LoginFormWrapper>
      </LoginContainerWrapper>
    </LoginWrapper>
  )
}

export default ShopifyLogin

const LoginWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
`

const LoginContainerWrapper = styled(Paper).attrs(() => ({
  elevation: 0
}))`
  width: 600px;
  min-height: 40vh;
  padding: 100px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  position: relative;

  @media ${Breakpoint.Phone} {
    width: 90vw;
    min-height: 50vh;
    padding: 50px 20px 20px;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;

  img {
    height: auto;
    width: 75px;
  }

  .logo-name {
    font-size: 2.25rem;
    font-weight: normal;

    span {
      font-weight: bold;
    }
  }

  @media ${Breakpoint.Phone} {
    img {
      height: auto;
      width: 50px;
    }

    .logo-name {
      font-size: 1.5rem;
    }
  }
`

const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${Breakpoint.Phone} {
    gap: 15px;
  }
`

const InputLabelWrapper = styled.span`
  text-align: center;
`

interface IAttrInput {
  classes: { root: string }
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputWrapper = styled(InputBase).attrs(
  (): IAttrInput => ({
    classes: { root: 'mui-input' }
  })
)`
  &.mui-input {
    padding: 20px;
    border: 2px solid ${Theme().Colors.Primary.MidnightBlue};
    border-radius: 5px;
    font-family: inherit;
    font-size: 1rem;
    letter-spacing: 0.2px;
  }

  @media ${Breakpoint.Phone} {
    &.mui-input {
      padding: 5px 10px;
    }
  }
`

const ButtonWrapper = styled(Button).attrs(() => ({
  classes: { root: 'mui-button' }
}))`
  &.mui-button {
    padding: 20px;
    background-color: ${Theme().Colors.Primary.MidnightBlue};
    border-radius: 5px;
    color: ${Theme().Colors.Text.Light.HighPrio};
    font-family: inherit;
    font-size: 1rem;
    letter-spacing: 0.2px;
    text-transform: capitalize;

    &:hover {
      background-color: ${Theme().Colors.Primary.MidnightBlue};
    }
  }

  @media ${Breakpoint.Phone} {
    &.mui-button {
      padding: 10px;
    }
  }
`
