import axios from 'axios'
import jwt from 'jsonwebtoken'
import { v4 as uuidv4 } from 'uuid'
import { API_URL, IS_PROD_BUILD, SHOPIFY_API_KEY, SHOPIFY_API_SECRET } from '../configs/config'

class ShopifyUtil {
  static getSessionToken(origin: string, shop: string) {
    const payload = {
      iss: origin,
      dest: shop,
      aud: SHOPIFY_API_KEY,
      jti: uuidv4()
    }

    return jwt.sign(payload, SHOPIFY_API_SECRET, {
      expiresIn: 60
    })
  }
}

export const shopifyFetchFn = axios.create({
  baseURL: `${API_URL}/api/shopify`
})

const searchParams = new URLSearchParams(window?.location?.search)
shopifyFetchFn.interceptors.request.use((config: any) => {
  const origin = IS_PROD_BUILD ? window?.location?.origin : API_URL
  const shop = searchParams.get('shop') || ''
  config.headers['authorization'] = `Bearer ${ShopifyUtil.getSessionToken(origin, shop)}`
  return config
})

export default ShopifyUtil
