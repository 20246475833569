import { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'

import ConfirmDialog from '../../shared/components/ConfirmDialog'
import ConfirmUpgrade from '../../shared/components/ConfirmUpgrade'

import ScoreUpLogo from '../../shared/assets/png/ScoreUpIcon.png'

import { ERROR_MESSAGE, SCAN_ALL_REQUEST_SENT } from '../../shared/constants/Messages'
import { Plans } from '../../shared/constants/Plans'
import { Routes, RoutesList } from '../../shared/constants/Routes'
import { ShopUrls } from '../../shared/constants/Shop'

import { useShopifyContext } from '../../shared/contexts/Shopify'
import { useAppSelector } from '../../shared/hooks/redux'
import ShopifyServices from '../../shared/services/ShopifyServices'
import { styled, Theme } from '../../shared/themes'
import { Breakpoint } from '../../shared/themes/Breakpoints'
import { formatUrls } from '../../shared/utils/Common'

const TopNavigation = () => {
  const history = useHistory()
  const location = useLocation()
  const shopifyContext = useShopifyContext()

  const userDetails = useAppSelector(state => state.userProfileSlice.record)
  const urlListState = useAppSelector(state => state.pageList)

  const [isScanAllConfirmVisible, setIsScanAllConfirmVisible] = useState(false)
  const [isUpgradeConfirmVisible, setIsUpgradeConfirmVisible] = useState(false)
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  const handleToggleScanAllConfirm = () => {
    setIsScanAllConfirmVisible(prevState => !prevState)
  }

  const handleToggleUpgradeConfirm = () => {
    setIsUpgradeConfirmVisible(prevState => !prevState)
  }

  const handleToggleMobileNav = () => {
    setIsMobileNavVisible(prevState => !prevState)
  }

  const handleScan = () => {
    if (Routes.Scan === location.pathname) handleToggleScanAllConfirm()
    else history.push(Routes.Scan)
  }

  const handleScanAll = useCallback(() => {
    const scanAll = async () => {
      try {
        const result = await ShopifyServices.getRemainingScans()
        const plan = result?.results?.plan
        const attemptsRemaining = result?.results?.attemptsRemaining || 0

        if (plan === Plans.Enterprise.name || attemptsRemaining >= ShopUrls.concat(urlListState.urls).length) {
          const urlList = formatUrls(shopifyContext.shopParam, ShopUrls.concat(urlListState.urls))
          await ShopifyServices.scanAllPage(urlList)
          toast.success(SCAN_ALL_REQUEST_SENT)
        } else {
          handleToggleUpgradeConfirm()
        }
      } catch (error) {
        toast.error(ERROR_MESSAGE)
      }

      handleToggleScanAllConfirm()
    }

    scanAll()
  }, [shopifyContext.shopParam, urlListState.urls])

  return (
    <>
      <TopNavigationWrapper>
        <LogoWrapper>
          <img src={ScoreUpLogo} />
          <h1 className="logo-name">
            <span>Score</span>Up
          </h1>
        </LogoWrapper>

        <MessageWrapper>
          <span>Hi {userDetails.name || ''}</span>, Welcome Back!
        </MessageWrapper>

        <MenuIconWrapper onClick={handleToggleMobileNav} />
        <NavigationWrapper>
          {Object.values(RoutesList).map(({ path, label }, key) => (
            <NavigationLinkWrapper
              key={key}
              $isSelected={path === location.pathname}
              onClick={() => history.push(path)}
            >
              {label}
            </NavigationLinkWrapper>
          ))}
        </NavigationWrapper>

        <ScanButtonWrapper onClick={handleScan}>
          {Routes.Scan === location.pathname ? 'Scan all' : 'Start Scan'}
        </ScanButtonWrapper>
      </TopNavigationWrapper>
      <MobileNavigationWrapper $isMobileNavVisible={isMobileNavVisible}>
        <div>
          {Object.values(RoutesList).map(({ path, label }, key) => (
            <MobileNavItemWrapper
              key={key}
              onClick={() => {
                handleToggleMobileNav()
                history.push(path)
              }}
            >
              {label}
            </MobileNavItemWrapper>
          ))}
        </div>
      </MobileNavigationWrapper>

      <ConfirmDialog
        isConfirmOpen={isScanAllConfirmVisible}
        id={'scan-all'}
        title={'Scan All'}
        message={'Are you sure you want to scan all pages?'}
        cancelLabel={'Cancel'}
        confirmLabel={'Confirm'}
        onClose={handleToggleScanAllConfirm}
        onConfirm={handleScanAll}
      />

      <ConfirmUpgrade
        isConfirmOpen={isUpgradeConfirmVisible}
        handleCloseConfirm={handleToggleUpgradeConfirm}
        currentPlan={userDetails.plan_name ?? Plans.Basic.name}
      />
    </>
  )
}

export default TopNavigation

const TopNavigationWrapper = styled.div`
  height: fit-content;
  display: grid;
  grid-template-areas:
    'logo message'
    'nav scan';
  background-color: white;
  padding: 25px 25px 20px;
  gap: 10px 20px;
  z-index: 1000;

  @media ${Breakpoint.Phone} {
    padding: 10px;

    grid-template-areas:
      'logo mobile-nav'
      'message scan';
  }
`

const LogoWrapper = styled.div`
  grid-area: logo;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: auto;
    width: 45px;
  }

  .logo-name {
    font-size: 1.25rem;
    font-weight: normal;

    span {
      font-weight: bold;
    }
  }
`

const MessageWrapper = styled.div`
  grid-area: message;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.5rem;

  span {
    font-weight: bold;
  }

  @media ${Breakpoint.Tablet}, ${Breakpoint.Phone} {
    margin: auto 0;
    font-size: 1rem;
  }

  @media ${Breakpoint.Phone} {
    display: block;
  }
`

const NavigationWrapper = styled.div`
  grid-area: nav;
  display: flex;
  gap: 10px;

  @media ${Breakpoint.Phone} {
    display: none;
  }
`

const MenuIconWrapper = styled(MenuIcon).attrs(() => ({
  classes: { root: 'mui-menu-icon' }
}))`
  &.mui-menu-icon {
    display: none;
  }

  @media ${Breakpoint.Phone} {
    &.mui-menu-icon {
      grid-area: mobile-nav;
      display: block;
      align-self: center;
      justify-self: end;
    }
  }
`

interface IMobileNavigationProps {
  $isMobileNavVisible?: boolean
}
const MobileNavigationWrapper = styled.div<IMobileNavigationProps>`
  display: none;

  @media ${Breakpoint.Phone} {
    display: block;
    position: relative;
    width: 100%;

    > div {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      position: absolute;
      top: ${props => (props.$isMobileNavVisible ? 0 : '-40vh')};
      visibility: ${props => (props.$isMobileNavVisible ? 'visible' : 'hidden')};
      height: 40vh;
      width: 100%;
      background-color: white;
      box-shadow: 0px 16px 13px -12px rgba(62, 79, 102, 0.1);
      -webkit-box-shadow: 0px 16px 13px -12px rgba(3, 28, 61, 0.1);
      -moz-box-shadow: 0px 16px 13px -12px rgba(3, 28, 61, 0.1);
      transition: all 0.5s ease;
      z-index: 100;
    }
  }
`

const MobileNavItemWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${Theme().Colors.Grey.Light};
  }
`

interface INavigationLinkProps {
  $isSelected?: boolean
}
const NavigationLinkWrapper = styled(Button).attrs(() => ({
  classes: { root: 'mui-button' }
}))<INavigationLinkProps>`
  &.mui-button {
    display: grid;
    place-items: center;
    min-width: 120px;
    padding: 5px 15px;
    background-color: ${props => (props.$isSelected ? Theme().Colors.Primary.MidnightBlue : Theme().Colors.Grey.Light)};
    border-radius: 5px;
    color: ${props => (props.$isSelected ? Theme().Colors.Text.Light.HighPrio : Theme().Colors.Text.Dark.HighPrio)};
    font-family: inherit;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      background-color: ${props => (props.$isSelected ? Theme().Colors.Primary.MidnightBlue : '#e2e4e9')};
    }
  }
`

const ScanButtonWrapper = styled(Button).attrs(() => ({
  classes: { root: 'mui-button' }
}))`
  &.mui-button {
    grid-area: scan;
    align-self: center;
    justify-self: end;
    display: grid;
    width: fit-content;
    padding: 5px 25px;
    background-color: ${Theme().Colors.Primary.Blue};
    border-radius: 5px;
    color: ${Theme().Colors.Text.Light.HighPrio};
    font-family: inherit;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: ${Theme().Colors.Primary.MidnightBlue};
    }
  }
`
