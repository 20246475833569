export const Status = {
  Completed: 'completed',
  Failed: 'failed',
  Pending: 'pending'
}

export const SpeedRange = {
  Slow: {
    Min: 1,
    Max: 49
  },
  Averange: {
    Min: 50,
    Max: 89
  },
  Fast: {
    Min: 90,
    Max: 100
  }
}
