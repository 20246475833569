import { Title } from '../constants/Plans'
import { IHttpResponse } from '../interface/Http'
import { IScanRecord, IScansRemaining } from '../interface/Scans'
import { IUrlResult } from '../interface/UrlResultInterface'
import { UserDetailResponse } from '../interface/UserDetailsInterface'
import { shopifyFetchFn } from '../utils/Shopify'

class ShopifyServices {
  static async login(shop: string) {
    const result = await shopifyFetchFn({
      method: 'POST',
      url: '/login',
      data: {
        shop
      }
    })
    return result.data as IHttpResponse<any, { authToken: string }>
  }

  static async getUserDetails(): Promise<UserDetailResponse> {
    const result = await shopifyFetchFn({
      method: 'GET',
      url: '/users/self'
    })
    return result.data as UserDetailResponse
  }

  static async getLastScanDetails(area?: string): Promise<IHttpResponse<IScanRecord>> {
    const result = await shopifyFetchFn({
      method: 'POST',
      url: '/scans/filter',
      data: {
        filters: {
          status: 'completed',
          area
        },
        orderBy: [['created_at', 'DESC']],
        limit: 1
      }
    })
    return result.data as IHttpResponse<IScanRecord>
  }

  static async getScanList(ordering: string, offset?: number | null): Promise<IHttpResponse<IScanRecord>> {
    const result = await shopifyFetchFn({
      method: 'POST',
      url: '/scans/filter',
      data: {
        orderBy: [['created_at', ordering]],
        limit: 20,
        offset
      }
    })
    return result.data as IHttpResponse<IScanRecord>
  }

  static async getScanListByArea(area?: string): Promise<IHttpResponse<IScanRecord>> {
    const result = await shopifyFetchFn({
      method: 'POST',
      url: '/scans/filter',
      data: {
        filters: {
          status: 'completed',
          area
        },
        orderBy: [['created_at', 'DESC']],
        limit: 6
      }
    })
    return result.data as IHttpResponse<IScanRecord>
  }

  static async scanPage(url: string, area?: string) {
    const response = await shopifyFetchFn({
      method: 'POST',
      url: '/scans',
      data: {
        url,
        area
      }
    })
    return response
  }

  static async scanAllPage(
    urls: {
      newUrl: string
      area: string
    }[]
  ) {
    for (const url of urls) {
      await this.scanPage(url.newUrl, url.area)
    }
  }

  static async getCollectionPages(): Promise<IUrlResult> {
    const response = await shopifyFetchFn({
      method: 'GET',
      url: '/pages'
    })
    return response.data as IUrlResult
  }

  static async createRecurringCharge(plan: Title): Promise<IHttpResponse<IScanRecord>> {
    const result = await shopifyFetchFn({
      method: 'POST',
      url: '/plans/subscribe',
      data: {
        plan
      }
    })
    return result.data as IHttpResponse<IScanRecord>
  }

  static async getRemainingScans(): Promise<IHttpResponse<IScansRemaining>> {
    const result = await shopifyFetchFn({
      method: 'GET',
      url: '/scans/attempts'
    })

    return result.data as IHttpResponse<IScansRemaining>
  }

  static async getScanDetail(scanId: string): Promise<IHttpResponse<null>> {
    const result = await shopifyFetchFn({
      method: 'GET',
      url: `/scans/details`,
      params: {
        id: scanId
      }
    })

    return result.data as IHttpResponse<null>
  }
}

export default ShopifyServices
