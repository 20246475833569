import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import TopNavigation from './Partial/TopNavigation'

import { useAppSelector } from '../shared/hooks/redux'
import { SocketPath } from '../shared/constants/Sockets'
import { PLAN_SUBSCRIPTION_UPDATE } from '../shared/constants/Messages'
import { useSocketsContext } from '../shared/contexts/Sockets'
import { styled } from '../shared/themes'
import { Breakpoint } from '../shared/themes/Breakpoints'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const currentUserId = useAppSelector(state => state.userProfileSlice.record.id)
  const { socket } = useSocketsContext()

  const [updatedPlan, setUpdatedPlan] = useState(null)

  useEffect(() => {
    socket.on(SocketPath.UserDetails, data => {
      setUpdatedPlan(data.plan_name)
    })

    return () => {
      socket.off(SocketPath.UserDetails)
    }
  }, [socket, currentUserId])

  useEffect(() => {
    updatedPlan ? toast.success(PLAN_SUBSCRIPTION_UPDATE(updatedPlan)) : null
  }, [updatedPlan])

  return (
    <LayoutWrapper>
      <TopNavigation />
      <ContainerWrapper>{children}</ContainerWrapper>
    </LayoutWrapper>
  )
}

export default Layout

const LayoutWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  width: 100vw;

  @media ${Breakpoint.Phone} {
    grid-template-rows: auto 0 1fr;
  }
`

const ContainerWrapper = styled.div`
  width: 100vw;
  overflow-y: auto;

  > div {
    overflow-x: hidden;
    overflow-y: auto;

    @media ${Breakpoint.Phone} {
      padding: 10px;
    }
  }
`
