import { useHistory } from 'react-router'

import ConfirmDialog from './ConfirmDialog'

import { Plans } from '../constants/Plans'
import { Routes } from '../constants/Routes'

interface ConfirmUpgradeProps {
  isConfirmOpen: boolean
  handleCloseConfirm: () => void
  currentPlan: string
}

const ConfirmUpgrade = ({ isConfirmOpen, handleCloseConfirm, currentPlan }: ConfirmUpgradeProps) => {
  const history = useHistory()

  const handleUpgrade = () => {
    handleCloseConfirm()
    history.push(Routes.Features)
  }

  return (
    <ConfirmDialog
      id="upgrade-confirmation"
      isConfirmOpen={isConfirmOpen}
      title={
        <>
          You have consumed all of your <span>daily scans</span>
        </>
      }
      message={`${currentPlan} Plan only allows ${Plans[currentPlan].maxScanAttempts} ${
        (Plans[currentPlan].maxScanAttempts ?? 0) > 1 ? 'scans' : 'scan'
      } per day. Please consider upgrading to ${Plans.Enterprise.name} for unlimited scans.`}
      cancelLabel={'Not Now'}
      confirmLabel={'Upgrade Now'}
      onClose={handleCloseConfirm}
      onConfirm={handleUpgrade}
    />
  )
}

export default ConfirmUpgrade
