import { styled } from '../../shared/themes'
import Loader from '../../shared/assets/gif/LoadingAnimation.gif'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
`
const Text = styled.p`
  margin-top: -80px;
  font-size: 16px;
  line-height: 24px;
`

const GifHolder = styled.img`
  width: 300px;
`

export default function LoaderWrapper() {
  return (
    <Container>
      <GifHolder src={Loader} />
      <Text>Preparing your file...</Text>
    </Container>
  )
}
