export enum Routes {
  Dashboard = '/',
  Scan = '/scan',
  Features = '/features',
  Login = '/login',
  Privacy = '/privacy-policy',
  Terms = '/terms-of-service'
}

export const RoutesList = {
  dashboard: {
    path: Routes.Dashboard,
    label: 'Dashboard'
  },
  scan: {
    path: Routes.Scan,
    label: 'Store'
  },
  features: {
    path: Routes.Features,
    label: 'Upgrade'
  }
}
